.background {
    position: fixed;
    z-index: -2;
    top: 0;
    left: 0;

    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;

    user-select: none;

    opacity: 0.85;
}
