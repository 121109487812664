* {
  font-family: "Rubik", sans-serif;
}

body {
  margin: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: black;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

#root {
  height: 100%;
}

.page {
  min-height: 100%;
  transform: translateY(20px);

  opacity: 0;
  animation: page--fade-in 0.3s forwards;
}

code {
  background-color: rgb(235, 239, 242);
  font-family: 'Roboto Mono', monospace;
  cursor: pointer;
  padding: 0px 5px;
  border-radius: 5px;
}

a {
  color: rgb(86, 86, 209);
  font-weight: bold;
}

a:hover {
  color: rgb(54, 54, 155);
}

blockquote {
  opacity: 0.75;
  border-left: 2px solid rgb(150, 150, 150);
  margin-left: 5px;
  padding-left: 10px;
}

@keyframes page--fade-in {
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

select,
textarea,
input {
  font-size: 16px;
}
