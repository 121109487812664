.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
}

.landing-page > * {
    width: 100%;
    max-width: 350px;

    flex-grow: 0;
    flex-shrink: 0;
}

.landing-page__spacer {
    flex-grow: 1;
}

.landing-page__logo {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 300px;
    height: 300px;

    user-select: none;
}

.landing-page__logo-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.landing-page__logo-image--bright {
    z-index: 2;
    animation: landing-page__logo-image--sub 4s ease-in-out 0s infinite alternate;
}

@keyframes landing-page__logo-image--sub {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.landing-page__version {
    position: absolute;
    bottom: 25px;
    right: 45px;

    color: white;
    font-size: 18px;

    user-select: none;

    animation: landing-page__version--fade-in linear 0.5s;
    text-shadow: 0px 1px 1px rgb(0 0 0 / 50%);
}

@keyframes landing-page__version--fade-in {
    from {
        opacity: 0;
    }
}

.landing-page__address-block {
    display: flex;
    flex-direction: column;

    align-items: flex-end;
    margin-bottom: 10px;
}

.landing-page__address-block > *:not(:last-child) {
    margin-bottom: 10px;
}

.landing-page__address {
    display: flex;
    border-radius: 10px;

    width: 100%;

    font-weight: 500;
    background-color: white;

    box-shadow: 0px 2px 1px 0px rgb(0 0 0 / 20%);
}

.landing-page__address-field {
    padding: 10px 10px;

    flex-grow: 1;
    text-align: center;
}

.landing-page__address-field input {
    border: none;
    background: none;
    outline: none;
    width: 100%;
    font-size: 16px;
    text-align: center;

    color: black;
}

.landing-page__address-copy-button {
    min-width: 30%;
    padding: 10px 10px;
    border-radius: 0px 10px 10px 0px;

    border: none;
    background: none;
    outline: none;
    cursor: pointer;

    background-color: rgb(228, 228, 228);
    color: black;

    transition: filter 0.2s;

    user-select: none;
}

.landing-page__address-copy-button:hover {
    filter: brightness(90%);
}

.landing-page__address-copy-button--copied {
    background-color: rgb(124, 214, 162);
}

.landing-page__actions {
    display: flex;
    margin-bottom: 20px;

    flex-direction: column;
    align-items: center;
}

.landing-page__actions > *:not(:last-child) {
    margin-bottom: 10px;
}

.landing-page__actions-row {
    display: flex;
    width: 100%;
}

.landing-page__actions-row > *:not(:last-child) {
    margin-right: 10px;
}

.landing-page__action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* position: relative; */

    border: none;
    background: none;
    outline: none;

    padding: 10px 20px;
    border-radius: 10px;

    background-color: white;
    color: black;

    cursor: pointer;
    transition: background-color 0.2s;

    width: 100%;

    box-shadow: 0px 2px 1px 0px rgb(0 0 0 / 20%);
    transition: filter 0.2s;

    user-select: none;
}

.landing-page__action:hover:not(:disabled) {
    filter: brightness(90%);
}

.landing-page__action:disabled {
    opacity: 0.5;
    cursor: unset;
}

.landing-page__action-icon {
    /* position: absolute; */
    /* left: 10px; */
    padding-bottom: 10px;
    width: 28px;
}

.landing-page__server-status {
    display: flex;
    justify-content: center;
}

.landing-page__server-status-text {
    margin-bottom: 20px;

    color: white;
    opacity: 0;

    user-select: none;

    animation: landing-page__server-status--fade-in linear 0.5s 0.5s forwards;
    text-shadow: 0px 1px 1px rgb(0 0 0 / 50%);
}

@keyframes landing-page__server-status--fade-in {
    to {
        opacity: 0.9;
    }
}
