.text-page {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.text-page__wrapper {
    width: 100%;
    max-width: 800px;
}

.text-page__content {
    width: 100%;
    box-sizing: border-box;

    background-color: white;
    border-radius: 10px;
    padding: 30px;

    overflow: hidden;
}

.text-page__content > h1,
.text-page__content > h2 {
    user-select: none;
}

.text-page__content img {
    width: 100%;
    border-radius: 10px;
}

.text-page__back-button {
    display: flex;
    padding: 20px;
    border-radius: 15px 15px 0px 0px;

    cursor: pointer;
    color: white;
    text-shadow: 0px 1px 1px rgb(0 0 0 / 50%);

    transition: opacity 0.2s;
}

.text-page__back-button--top {
    margin-top: 20px;
}

.text-page__back-button--bottom {
    margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
    .text-page__content {
        padding: 20px;
    }

	.text-page__back-button--top {
        margin-top: 0px;
    }

    .text-page__back-button--bottom {
        margin-bottom: 0px;
    }
}

.text-page__back-button:hover {
    opacity: 0.7;
}

.text-page__back-button-icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    filter: invert() drop-shadow(0px 1px 1px rgb(0 0 0 / 50%));
}

.text-page__back-button-label {
    user-select: none;
}
